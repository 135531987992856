import request from '@/utils/request';

const common = {
  products_and_variants: () => request.get('/coupons/products_and_variants.json'),
};

const users = {
  sign_in: (params = {}) => request.post('/users/sign_in.json', params),
  list: (params = {}) => request.get(`/users.json`, { params }),
  create: (params = {}) => request.post('/users.json', params),
  update: (id: number | string, params = {}) => request.patch(`/users/${id}.json`, params),
  delete: (id: number | string) => request.delete(`/users/${id}.json`),
  editPassword: (params = {}) => request.put(`/users/update_password.json`, params),
};

const dashboard = {
  list: () => request.get(`/dashboard.json`),
  top_sale_variants: (params = {}) => request.get(`/dashboard/top_sale_variants.json`, { params }),
};

const analysis = {
  sale_statistic: (params = {}) => request.get(`/analysis/sale_statistic.json`, { params }),
};

const orders = {
  list: (params = {}) => request.get('/orders.json', { params }),
  detail: (id: number | string) => request.get(`/orders/${id}.json`),
  refund: (id: number | string) => request.post(`/orders/${id}/refund`),
  sync_order: (id: number | string) => request.get(`/orders/${id}/sync_routes`),
  export: (params = {}) => request.get('/orders/export.json', { params }),
  transferLogs: (params = {}) => request.get('/transfer_logs.json', { params }),
};

const products = {
  publishedList: () => request.get('/products/published.json'),
  list: (params = {}) => request.get('/products.json', { params }),
  detail: (id: number | string) => request.get(`/products/${id}.json`),
  create: (params = {}) => request.post(`/products.json`, params),
  copy: (id: number | string) => request.post(`/products/${id}/copy.json`),
  update: (id: number | string, params = {}) => request.patch(`/products/${id}.json`, params),
  delete: (id: number | string) => request.delete(`/products/${id}.json`),
  publish: (id: number | string) => request.patch(`/products/${id}/publish.json`),
  unpublish: (id: number | string) => request.patch(`/products/${id}/unpublish.json`),
  restore: (id: number | string, variant_id: number | string) =>
    request.post(`/products/${id}/undiscard_variant.json`, { variant_id }),
  moveHeadImage: (id: number | string, params = {}) =>
    request.patch(`/products/${id}/move_head_image.json`, params),
  moveBodyImage: (id: number | string, params = {}) =>
    request.patch(`/products/${id}/move_body_image.json`, params),
  updateImage: (id: number | string, params = {}) =>
    request.patch(`/products/${id}/update_image.json`, params),
  addImages: (id: number | string, params = {}) =>
    request.patch(`/products/${id}/add_images.json`, params),
  notify: (id: number | string) => request.post(`/products/${id}/onsale_notice.json`),
};

const options = {
  products: (params = {}) => request.get('/products/selectable.json', { params }),
};

const images = {
  detail: (id: number | string) => request.get(`/images/${id}.json`),
  delete: (id: number | string) => request.delete(`/images/${id}.json`),
};

const variants = {
  create: (params = {}) => request.post('/variants.json', params),
  update: (id: number | string, params = {}) => request.patch(`/variants/${id}.json`, params),
  detail: (id: number | string) => request.get(`/variants/${id}.json`),
  discard: (id: number | string) => request.patch(`/variants/${id}/discard.json`),
  undiscard: (id: number | string) => request.patch(`/variants/${id}/undiscard.json`),
  verifErp: (sku: string) => request.get('/variants/bottle_count.json', { params: { sku } }),
};

const services = {
  create: (params = {}) => request.post('/sale_services.json', params),
  list: (params = {}) => request.get('/sale_services.json', { params }),
  get: (id: number | string) => request.get(`/sale_services/${id}.json`),
  update: (id: number | string, params = {}) => request.patch(`/sale_services/${id}.json`, params),
  toggle: (id: number | string) => request.patch(`/sale_services/${id}/toggle.json`),
  delete: (id: number | string) => request.delete(`/sale_services/${id}.json`),
};

const pointServices = {
  list: () => request.get('/point_services.json'),
  move: (id: number | string, position: number) =>
    request.patch(`/point_services/${id}/move`, { position }),
};

const pointTransfers = {
  pointOrders: (params = {}) => request.get('/point_transfers/point_orders.json', { params }),
  customers: (params = {}) => request.get('/point_transfers/customers.json', { params }),
  export: (params = {}) => request.get('/point_transfers/export.csv', { params }),
  batchExport: (params = {}) => request.get('/point_transfers/batch_export.csv', { params }),
};

const collections = {
  list: (params = {}) => request.get('/collections.json', { params }),
  detail: (id: number | string) => request.get(`/collections/${id}.json`),
  publish: (id: number | string) => request.patch(`/collections/${id}/publish`),
  unpublish: (id: number | string) => request.patch(`/collections/${id}/unpublish`),
  destroy: (id: number | string) => request.delete(`/collections/${id}`),
  create: (params = {}) => request.post(`/collections`, params),
  update: (id: number | string, params = {}) => request.patch(`/collections/${id}`, params),
  optionalItems: (id: number | string) => request.get(`/collections/${id}/addable_items.json`),
  handleIdentifiers: () => request.get('/collections/identifiers.json'),
};

const collects = {
  create: (params = {}) => request.post(`/collects.json`, params),
  destroy: (id: number | string) => request.delete(`/collects/${id}`),
  move: (id: number | string, position: number) =>
    request.patch(`/collects/${id}/move`, { position }),
};

const customer = {
  list: (params = {}) => request.get('/customers.json', { params }),
  detail: (id: number | string) => request.get(`/customers/${id}.json`),
  send: (id: number | string, params = {}) => request.post(`/customers/${id}/send_coupon`, params),
  tab: (id: number | string, params = {}) => request.get(`/customers/${id}/tab.json`, { params }),
  upload: (params = {}) => request.post(`/customers/balance_import`, params),
  staffList: (params = {}) => request.get('/customers/staff_list.json', { params }),
  couponActived: (params = {}) => request.get('/customers/coupon_actived.json', { params }),
  addTag: (id: number | string, params = {}) =>
    request.patch(`/customers/${id}/add_tag.json`, params),
  removeTag: (id: number | string, params = {}) =>
    request.patch(`/customers/${id}/remove_tag.json`, params),
  saveManualOrder: (params = {}) => request.post('/orders/save_manual_order.json', params),
  updateManualOrder: (id: number | string, params = {}) => request.put(`/orders/${id}`, params),
  paidManualOrder: (id: number | string, params = {}) =>
    request.post(`/orders/${id}/paid_manual_order`, params),
  bindVaichuang: (id: number | string, params = {}) =>
    request.patch(`/customers/${id}/bind_vaichuang`, params),
  pointTransfers: (id: number | string, params = {}) =>
    request.get(`/customers/${id}/point_transfers.json`, { params }),
};

const coupons = {
  summary: () => request.get('/coupons/summary.json'),
  list: (params = {}) => request.get('/coupons.json', { params }),
  create: (params = {}) => request.post('/coupons', params),
  update: (id: number | string, params = {}) => request.patch(`/coupons/${id}`, params),
  detail: (id: number | string) => request.get(`/coupons/${id}.json`),
  enable: (id: number | string) => request.patch(`/coupons/${id}/enabled`),
  disable: (id: number | string) => request.patch(`/coupons/${id}/disabled`),
  copy: (id: number | string) => request.post(`/coupons/${id}/copy`),
};

const couponBundles = {
  list: (params = {}) => request.get('/coupon_bundles.json', { params }),
  create: (params = {}) => request.post('/coupon_bundles', params),
  update: (id: number | string, params = {}) => request.patch(`/coupon_bundles/${id}`, params),
  destroy: (id: number) => request.delete(`/coupon_bundles/${id}`),
  detail: (id: number | string) => request.get(`/coupon_bundles/${id}.json`),
  collectors: (id: number | string) => request.get(`/coupon_bundles/${id}/collectors.json`),
  all_coupons: () => request.get('/coupon_bundles/all_coupons.json'),
  associate_coupons: () => request.get('/coupon_bundles/associate_coupons.json'),
};

const promotion = {
  list: () => request.get('/gift_settings.json'),
  create: (data = {}) => request.post('/gift_settings/batch_create.json', data),
  update: (id: string | number, data = {}) => request.patch(`/gift_settings/${id}.json`, data),
  delete: (id: string | number) => request.delete(`/gift_settings/${id}.json`),
};

const articles = {
  list: (params = {}) => request.get('/articles.json', { params }),
  detail: (id: number | string) => request.get(`/articles/${id}.json`),
  delete: (id: number | string) => request.delete(`/articles/${id}.json`),
  create: (data = {}) => request.post(`/articles.json`, data),
  update: (id: number | string, data = {}) => request.patch(`/articles/${id}.json`, data),
};

const banner = {
  list: (params = {}) => request.get('/banners.json', { params }),
  update: (id: number | string, data = {}) => request.patch(`/banners/${id}.json`, data),
  create: (data = {}) => request.post(`/banners.json`, data),
  detail: (id: number | string) => request.get(`/banners/${id}.json`),
  delete: (id: number | string) => request.delete(`/banners/${id}.json`),
  move: (id: number | string, data = {}) => request.patch(`/banners/${id}/move.json`, data),
};

const tags = {
  customer_tags: () => request.get('/acts_as_taggable_on_tags/customer_tags.json'),
  create: (data = {}) => request.post(`/acts_as_taggable_on_tags`, data),
};

const refundManagement = {
  agree: (id: number | string) => request.put(`/trade_orders/${id}?deal_action=agree`),
  refused: (id: number | string) => request.put(`/trade_orders/${id}?deal_action=refused`),
  list: (params = {}) => request.get(`/trade_orders`, { params }),
};

const qrcodes = {
  create: (data = {}) => request.post(`/qrcodes.json`, data),
  update: (id: number | string, data = {}) => request.patch(`/qrcodes/${id}.json`, data),
  list: (params = {}) => request.get('/qrcodes.json', { params }),
  detail: (id: number | string) => request.get(`/qrcodes/${id}.json`),
};

const channelsSharer = {
  list: (params = {}) => request.get('/third_party/accounts.json', { params }),
  detail: (id: number | string, params = {}) =>
    request.get(`/third_party/accounts/${id}.json`, { params }),
  createRecord: (data = {}) => request.post('/third_party/consume_records.json', data),
  bindCustomer: (id: number | string, data = {}) =>
    request.patch(`/third_party/accounts/${id}/bind_customer.json`, data),
  sync: () => request.post('/third_party/accounts/sync_account.json'),
  batchUpdate: (data = {}) => request.post(`/third_party/accounts/sync_asset`, data),
};

const settings = {
  list: (params = {}) => request.get('/settings.json', { params }),
  update: (id: number | string, data = {}) => request.patch(`/settings/${id}.json`, data),
  create: (data = {}) => request.post(`/settings.json`, data),
  detail: (id: number | string) => request.get(`/settings/${id}.json`),
};

const wechatMessages = {
  list: (params = {}) => request.get('/wechat_messages.json', { params }),
  detail: (id: number | string) => request.get(`/wechat_messages/user/${id}.json`),
};

/**
 * 按菜单顺序导出
 */
export default {
  common,
  users,
  dashboard,
  analysis,
  orders,
  products,
  pointServices,
  pointTransfers,
  variants,
  collects,
  collections,
  customer,
  coupons,
  couponBundles,
  promotion,
  articles,
  banner,
  tags,
  refundManagement,
  services,
  images,
  qrcodes,
  channelsSharer,
  options,
  settings,
  wechatMessages,
};
